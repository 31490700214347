import React from "react"
import { Link } from "gatsby"
import "../../styles/sell-landing.css"
import LandingLogo from "../../images/landing/landing-logo.png"
import SellBanner from "../../images/sell-landing/sell-banner.png"
import ChomuFarm from "../../images/sell-landing/ChomuFarm.jpg"
import MehwaFarm from "../../images/sell-landing/MehwaFarm.jpg"
import BeelwaFarm from "../../images/sell-landing/BeelwaFarm.jpeg"
import TihariFarm from "../../images/sell-landing/TihariFarm.jpg"
import WhyImages1 from "../../images/sell-landing/why-images1.png"
import WhyImages2 from "../../images/sell-landing/why-images2.png"
import WhyImages3 from "../../images/sell-landing/why-images3.png"
import WhyImages4 from "../../images/sell-landing/why-images4.png"
import ArrowBtn from "../../images/sell-landing/arrow-btn.png"
import Delevery from "../../images/sell-landing/delivery.png"
import Syrienge from "../../images/sell-landing/syringe.png"
import EasyCall from "../../images/sell-landing/call.png"
import EasyCheck from "../../images/sell-landing/check.png"
import EasyAmbulence from "../../images/sell-landing/ambulence.png"
import EasyDoctor from "../../images/sell-landing/doctor.png"
import BeliveImg1 from "../../images/sell-landing/beliveimg1.png"
import BeliveImg2 from "../../images/sell-landing/beleiveimg2.png"
import BeliveImg3 from "../../images/sell-landing/beleiveimg3.png"
import BeliveImg4 from "../../images/sell-landing/beleiveimg4.png"
import sellInstagram from "../../images/sell-landing/sellinstagram.png"
import sellFacebook from "../../images/sell-landing/sellfacebook.png"
import sellLinkedin from "../../images/sell-landing/selllinkedin.png"
import sellYoutube from "../../images/sell-landing/sellyoutube.png"
import Rating from "../../images/sell-landing/stars.png"
import GooglePlay from "../../images/sell-landing/googleplay.png"

import BagIcon from "../../images/landing/bagimg.png"
import CallButton from "../../images/landing/callbtn.png"
import { Helmet } from "react-helmet"
import Slider from "react-slick"
import VideoPlayer from "../../components/videoplayer/VideoPlayer"

import $ from "jquery"
import ReactPlayer from "react-player"

let sellData = [
  {
    name: "एचएफ",
    price: "59000",
    calving: 2,
    milk: 22,
    prgenancy: "ताज़ा ब्यायी",
    url:
      "https://res.cloudinary.com/mooofarm/video/upload/c_limit,w_480/4815673882_116816_admin_1_video_WhatsApp%20Video%202022-01-17%20at%206.18.12%20PM.mp4.mp4",
  },
  {
    name: "मुर्राह क्रॉस",
    price: "76000",
    calving: 2,
    milk: 9,
    prgenancy: "ताज़ा ब्यायी",
    url:
      "https://res.cloudinary.com/mooofarm/video/upload/c_limit,w_480/4815673882_118867_admin_1_video_WhatsApp%20Video%202022-01-30%20at%2010.00.38%20AM.mp4.mp4",
  },
  {
    name: "मुर्राह",
    price: "66000",
    calving: 2,
    milk: 12,
    prgenancy: "ताज़ा ब्यायी",
    url:
      "https://res.cloudinary.com/mooofarm/video/upload/c_limit,w_480/4815518066_120375_admin_1_video_WhatsApp%20Video%202022-02-11%20at%204.35.33%20PM.mp4.mp4",
  },
  {
    name: "मुर्राह क्रॉस",
    price: "66000",
    calving: 1,
    milk: 9,
    prgenancy: "ताज़ा ब्यायी",
    url:
      "https://res.cloudinary.com/mooofarm/video/upload/c_limit,w_480/4815673882_121740_admin_1_video_WhatsApp%20Video%202022-02-19%20at%2010.27.42%20AM.mp4.mp4",
  },
  {
    name: "मुर्राह क्रॉस",
    price: "76000",
    calving: 2,
    milk: 9,
    prgenancy: "ताज़ा ब्यायी",
    url:
      "https://res.cloudinary.com/mooofarm/video/upload/c_limit,w_480/4815673882_121743_admin_1_video_WhatsApp%20Video%202022-02-19%20at%209.59.57%20AM.mp4.mp4",
  },
  {
    name: "एचएफ",
    price: "53000",
    calving: 2,
    milk: 20,
    prgenancy: "ताज़ा ब्यायी",
    url:
      "https://res.cloudinary.com/mooofarm/video/upload/c_limit,w_480/4815897512_123535_admin_1_video_WhatsApp%20Video%202022-03-07%20at%2012.25.02%20PM.mp4.mp4",
  },
  {
    name: "मुर्राह क्रॉस",
    price: "79000",
    calving: 2,
    milk: 9.5,
    prgenancy: "ताज़ा ब्यायी",
    url:
      "https://res.cloudinary.com/mooofarm/video/upload/c_limit,w_480/4815518066_124182_admin_1_video_WhatsApp%20Video%202022-04-02%20at%205.16.10%20PM.mp4.mp4",
  },
  {
    name: "देसी भैंस",
    price: "81000",
    calving: 2,
    milk: 12,
    prgenancy: "ताज़ा ब्यायी",
    url:
      "https://res.cloudinary.com/mooofarm/video/upload/c_limit,w_480/4815121671_124645_admin_1_video_WhatsApp%20Video%202022-03-17%20at%206.12.05%20AM.mp4.mp4",
  },
  {
    name: "मुर्राह क्रॉस",
    price: "88000",
    calving: 2,
    milk: 12,
    prgenancy: "ताज़ा ब्यायी",
    url:
      "https://res.cloudinary.com/mooofarm/video/upload/c_limit,w_480/4815673882_125422_admin_1_video_WhatsApp%20Video%202022-03-23%20at%2012.15.37%20PM.mp4.mp4",
  },
  {
    name: "मिक्स भैंस",
    price: "75000",
    calving: 1,
    milk: 12,
    prgenancy: "ताज़ा ब्यायी",
    url:
      "https://res.cloudinary.com/mooofarm/video/upload/c_limit,w_480/4815432507_126500_admin_1_video_WhatsApp%20Video%202022-04-03%20at%2012.49.07.mp4.mp4",
  },
]
let utmObj;
let testimonialVideos = [
  { url: "https://cdn.mooofarm.com/videos/testimonialvideo1.mp4" },
  { url: "https://cdn.mooofarm.com/videos/testimonialvideo2.mp4" },
  { url: "https://cdn.mooofarm.com/videos/testimonialvideo3.mp4" },
]
class Landing extends React.Component {
  // const [mobile, setMobile] = useState('');
  constructor(props) {
    super(props)
    this.state = {
      mobile: "",
      mobilehref: "",
      showMessage: false,
      showThanksMessage: false,
    }
    if (typeof window !== "undefined")
      window.addEventListener("scroll", this.handleScroll, true)
  }
  fbqTrack = (eventName, params = {}) => {
    if (typeof fbq === "function") {
      window.fbq("trackCustom", eventName, { ...params })
    }
  }
  componentDidMount() {
    this.fbqTrack("Visitors",{utmObj});
    const queryString = window.location.search
    const paramsofquery = new URLSearchParams(queryString);
    if (paramsofquery.get('utm_source') && paramsofquery.get('utm_medium') && paramsofquery.get('utm_campaign') && paramsofquery.get('utm_content')) {
       utmObj = {
        'utm_source': paramsofquery.get('utm_source'),
        'utm_medium': paramsofquery.get('utm_medium'),
        'utm_campaign': paramsofquery.get('utm_campaign'),
        'utm_content': paramsofquery.get('utm_content')
      }
    } else {
       utmObj = {}
    }
  window.analytics.track("Visitors",{utmObj})
    
  }
  handleScroll = () => {
    let that = this
    for (let i = 0; i < 11; i++) {
      if (that[`myRef${i}`]?.player?.player != undefined) {
        that[`myRef${i}`].player.player.pause()
      }
    }
    for (let i = 0; i < 4; i++) {
      if (that[`myRefs${i}`]?.player?.player != undefined) {
        that[`myRefs${i}`].player.player.pause()
      }
    }
  }

  handleMobileChange = () => {
    if (this.state.mobile.length == 10) {
      this.fbqTrack("LP Lead",{utmObj,contactNo:this.state.mobile})
      window.analytics.track("LP Lead",{utmObj,contactNo : this.state.mobile})
      this.setState({ mobilehref: "tel:07901729630", showThanksMessage: true })
      let dialer_data = {
        campaignID: 'CSPLMOOFARM',
        contactNo: this.state.mobile,
        eventName: 'LP Lead',
        sourceName: 'Sell Landing Page',
        adID: Number()
      }
      fetch('https://api.mooofarm.com/v3/api/dialer', {
        method: "POST",
        body: JSON.stringify(dialer_data),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }).then((respose) =>
        respose.json()
      ).catch(err => err);
    } else {
      this.setState({ mobilehref: "javascript:void(0)" })
      this.setState({ showMessage: true })
      return
    }
  }

  handleMobileInputChange = e => {
    this.setState({ mobile: e.target.value })
  }

  handleMobileInputChange = e => {
    this.setState({ mobile: e.target.value })
  }
  
  handleClick = () =>{
    window.analytics.track("LP Buy Initiated",{utmObj});
     this.fbqTrack("LP Buy Initiated",{utmObj})
 }
  render() {
    const settings = {
      className: "slider variable-width slider-box",
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      autoplay: false,
      cssEase: "linear",
    }
    const settings1 = {
      className: "slider variable-width slider-box",
      dots: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      autoplay: false,
      afterChange: index => {
        let _this = this
        _this[`myRef${index}`].player.player.pause()
        if (index != 0) {
          _this[`myRef${index - 1}`].player.player.pause()
        }
      },
      beforeChange: index => {
        let _this = this
        _this[`myRef${index}`].player.player.pause()
        if (index != 9) {
          _this[`myRef${index + 1}`].player.player.pause()
        }
      },
    }

    const settings2 = {
      className: "slider variable-width slider-box",
      dots: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      autoplay: false,
      afterChange: index => {
        let _this = this
        _this[`myRefs${index}`].player.player.pause()
        if (index != 0) {
          _this[`myRefs${index - 1}`].player.player.pause()
        }
      },
      beforeChange: index => {
        let _this = this
        _this[`myRefs${index}`].player.player.pause()
        if (index != 2) {
          _this[`myRefs${index + 1}`].player.player.pause()
        }
      },
    }

    const client = {
      className: "client-slider-width",
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
    // onScroll = () =>{}
    return (
      <div>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700&display=swap"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            charset="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
          />
          <script src="js/script.js"></script>
          <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
          <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
        </Helmet>
        <div className="container sell-banner-container">
          <div className="row">
            <div className="col-xs-12">
              <img src={SellBanner} alt="Home" className="home-banner" />
            </div>
          </div>
          <div className="sell-banner-content-box">
            <div className="row">
              <div className="col-xs-12 text-left">
                <img src={LandingLogo} alt="Logo" className="main-logo" />
              </div>
            </div>
            <div className="row text-left">
              <div className="col-xs-12">
                <p className="sell-banner-heading">
                  भैंस हो या गाय<br></br>
                  सही दाम पर पशु पाएं
                </p>
                <a
                  className="sell-banner-btn"
                  data-toggle="modal"
                  data-target="#myModal"
                  onClick={this.handleClick}
                >
                  अभी फार्म पर आएं{" "}
                  <img src={ArrowBtn} className="btn-arrow" alt="Arrow Btn" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container address-container">
          <div className="mooofeed-content-box">
            <div className="row">
              <div className="col-xs-12">
                <h2
                  className="how-purchase-heading"
                  data-toggle="modal"
                  data-target="#videoModal"
                >
                  मू फार्म से कैसे खरीदे? वीडियो देखे
                </h2>
                <hr className="pink-border" />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 text-left">
                <h2 className="slider1-heading">ज़रूर आइये हमारे फार्म पर</h2>
                <h3 className="slider1-sub-heading">
                  पशु का सही दाम देता है मूफार्म!
                </h3>
                <Slider {...settings}>
                  <div>
                    <div className="sell-Slide-Box">
                      <img
                        src={ChomuFarm}
                        alt="Slide"
                        className="slide-image"
                      />
                      <div className="Slide-Box-Content address-slide">
                        <h3 className="sell-slide-heading">मूफार्म चौमू</h3>
                        <p className="sell-slide-address">
                          <b>Full Address:</b> NH 52 हाड़ोता चौराहा, चारा मंडी
                          के पास , चौमू, जयपुर, राजस्थान,पिनकोड 303702
                        </p>
                        <p className="sell-slide-time">
                          Open : 10 am - 8pm (Mon - Sun)
                        </p>
                        <a
                          className="slider-btn"
                          data-toggle="modal"
                          data-target="#myModal"
                          onClick={this.handleClick}
                        >
                          पशु देखने आएं
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="sell-Slide-Box">
                      <img
                        src={MehwaFarm}
                        alt="Slide"
                        className="slide-image"
                      />
                      <div className="Slide-Box-Content address-slide">
                        <h3 className="sell-slide-heading">मूफार्म महवा</h3>
                        <p className="sell-slide-address">
                          <b>Full Address:</b> हड़िया बस स्टॉप और पंडित होटल के
                          सामने, भरतपुर रोड, महवा, जिला दौसा पिन कोड: 321608
                        </p>
                        <p className="sell-slide-time">
                          Open : 10 am - 8pm (Mon - Sun)
                        </p>
                        <a
                          className="slider-btn"
                          data-toggle="modal"
                          data-target="#myModal"
                          onClick={this.handleClick}
                        >
                          पशु देखने आएं
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="sell-Slide-Box">
                      <img
                        src={BeelwaFarm}
                        alt="Slide"
                        className="slide-image"
                      />
                      <div className="Slide-Box-Content address-slide">
                        <h3 className="sell-slide-heading">मूफार्म बीलवा</h3>
                        <p className="sell-slide-address">
                          <b>Full Address:</b> ग्राम पोस्ट बीलवा, मीणा का
                          मोहल्ला तहसील सांगानेर, (एन. एच. 12 टोंक रोड, चौकी
                          धानी के पास) जिला जयपुर, राजस्थान पिन कोड - 302022
                        </p>
                        <p className="sell-slide-time">
                          Open : 10 am - 8pm (Mon - Sun)
                        </p>
                        <a
                          className="slider-btn"
                          data-toggle="modal"
                          data-target="#myModal"
                          onClick={this.handleClick}
                        >
                          पशु देखने आएं
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="sell-Slide-Box">
                      <img
                        src={TihariFarm}
                        alt="Slide"
                        className="slide-image"
                      />
                      <div className="Slide-Box-Content address-slide">
                        <h3 className="sell-slide-heading">मूफार्म तिहारी</h3>
                        <p className="sell-slide-address">
                          <b>Full Address:</b> तेजाजी चौक भाकरों का मोहल्ला,
                          मुकाम पोस्ट तिहारी वाया श्रीनगर, जिला अजमेर, पिन
                          कोड-305027
                        </p>
                        <p className="sell-slide-time">
                          Open : 10 am - 8pm (Mon - Sun)
                        </p>
                        <a
                          className="slider-btn"
                          data-toggle="modal"
                          data-target="#myModal"
                          onClick={this.handleClick}
                        >
                          पशु देखने आएं
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="sell-Slide-Box">
                      <img
                        src={BeelwaFarm}
                        alt="Slide"
                        className="slide-image"
                      />
                      <div className="Slide-Box-Content address-slide">
                        <h3 className="sell-slide-heading">
                          मूफार्म श्रीगंगानगर
                        </h3>
                        <p className="sell-slide-address">
                          <b>Full Address:</b> साधू कॉलोनी, प्लॉट नंबर 122, गली
                          नंबर - 1, एस. एस. बी. रोड के पास, श्रीगंगानगर,
                          राजस्थान - 335001
                        </p>
                        <p className="sell-slide-time">
                          Open : 10 am - 8pm (Mon - Sun)
                        </p>
                        <a
                          className="slider-btn"
                          data-toggle="modal"
                          data-target="#myModal"
                          onClick={this.handleClick}
                        >
                          पशु देखने आएं
                        </a>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
        <div className="container why-purchase-container">
          <div className="row">
            <div className="col-xs-12">
              <h2 className="mooofeed-heading">
                क्यों खरीदें पशु हमारे फार्म से?
              </h2>
            </div>
            <div className="col-xs-12 why-image-box">
              <img src={WhyImages1} alt="Why Images 1" className="why-images" />
              <div className="why-content-box">
                <h3 className="why-headind">पशु ट्रांसपोर्ट बिलकुल फ्री</h3>
              </div>
            </div>

            <div className="col-xs-12 why-image-box">
              <img src={WhyImages2} alt="Why Images 1" className="why-images" />
              <div className="why-content-box">
                <h3 className="why-headind">दूध, ब्यात, नस्ल की 100% गारंटी</h3>
              </div>
            </div>

            <div className="col-xs-12 why-image-box">
              <img src={WhyImages3} alt="Why Images 1" className="why-images" />
              <div className="why-content-box">
                <h3 className="why-headind">डॉक्टर सुविधा बिलकुल फ्री</h3>
              </div>
            </div>

            <div className="col-xs-12 why-image-box">
              <img src={WhyImages4} alt="Why Images 1" className="why-images" />
              <div className="why-content-box">
                <h3 className="why-headind">
                  मूपका-खुरपका और लंगड़ा रोग के टीके लगे हुए पशु!
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="container purchase-mooo-container">
          <div className="row text-center">
            <div className="col-xs-12">
              <a
                className="purchase-mooo"
                data-toggle="modal"
                data-target="#myModal"
                onClick={this.handleClick}
              >
                पशू खरीदें{" "}
                <img src={ArrowBtn} className="btn-arrow" alt="Arrow Btn" />
              </a>
              <p className="purchase-mooo-text">
                डेयरी को ले जाएँ मुनाफे की ओर
              </p>
            </div>
          </div>
        </div>

        <div className="container benifit-mooo-container">
          <div className="row">
            <div className="col-xs-12">
              <h2 className="benifit-mooo-heading">सबसे मुनाफेदार पशु:</h2>
              <Slider {...settings1}>
                {sellData.map((item, index) => {
                  return (
                    <div>
                      <div className="sell-Slide-video-Box">
                        <div className="sell-video-player">
                          <VideoPlayer
                            key={index}
                            refs={ref => (this[`myRef${index}`] = ref)}
                            url={item.url}
                          />
                        </div>
                        <div className="Slide-Box-Content">
                          <h3 className="listing-heading">{item.name}</h3>
                          <p className="listing-price">₹{item.price}</p>
                          <div className="row gray-btn-row">
                            <div className="col-xs-4 list-gray-btn-box1">
                              <p className="list-gray-btn">
                                {item.calving} बार ब्याही
                              </p>
                            </div>
                            <div className="col-xs-4 list-gray-btn-box2">
                              <p className="list-gray-btn">
                                दूध {item.milk} लीटर
                              </p>
                            </div>
                            <div className="col-xs-4 list-gray-btn-box3">
                              <p className="list-gray-btn">{item.prgenancy}</p>
                            </div>
                          </div>
                          <div className="col-xs-5 list-green-btn-box2">
                            <p className="list-green-btn">
                              टीके लगे हुए{" "}
                              <img
                                src={Syrienge}
                                alt="Free"
                                className="list-free-icon-row"
                              />
                            </p>
                          </div>
                          <div className="col-xs-2 list-gray-btn-box3"></div>
                        </div>
                        <a
                          className="slider-btn"
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          पशु देखने आएं
                        </a>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
            <div className="col-xs-12 easy-four-point">
              <h3 className="easy-heading">इस आसान तरीके से खरीदें पशु</h3>
              <div className="row easy-four-point-row">
                <div className="col-xs-2 text-center">
                  <img src={EasyCall} alt="Call" className="four-point-img" />
                </div>
                <div className="col-xs-10">
                  <p className="four-point-heading">संपर्क करें</p>
                  <p className="four-point-sub-heading">
                    बस एक कॉल करके सारी जानकारी पाएं
                  </p>
                </div>
              </div>
              <div className="row easy-four-point-row2">
                <div className="col-xs-2 text-center">
                  <img src={EasyCheck} alt="Call" className="four-point-img" />
                </div>
                <div className="col-xs-10">
                  <p className="four-point-heading">पशु की जांच करें</p>
                  <p className="four-point-sub-heading">
                    पशु के दूध, ब्यात, नस्ल की जांच करें
                  </p>
                </div>
              </div>
              <div className="row easy-four-point-row2">
                <div className="col-xs-2 text-center">
                  <img
                    src={EasyAmbulence}
                    alt="Call"
                    className="four-point-img"
                  />
                </div>
                <div className="col-xs-10">
                  <p className="four-point-heading">फ्री डिलीवरी</p>
                  <p className="four-point-sub-heading">
                    पशु की फ्री डिलीवरी अपने घर तक पाएं
                  </p>
                </div>
              </div>
              <div className="row easy-four-point-row2">
                <div className="col-xs-2 text-center">
                  <img src={EasyDoctor} alt="Call" className="four-point-img" />
                </div>
                <div className="col-xs-10">
                  <p className="four-point-heading">फ्री डॉक्टर सुविधा</p>
                  <p className="four-point-sub-heading">
                    पशु घर पहुंचने के बाद भी फ्री डॉक्टर सुविधा पाएं
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xs-12 easy-four-point">
              <h3 className="easy-heading">
                मूफार्म - लाखों किसानों का विश्वास
              </h3>
              <div className="row belive-four-point-row">
                <div className="col-xs-6 belive-box left">
                  <img
                    src={BeliveImg1}
                    alt="Belive Img"
                    className="belive-img"
                  />
                  <div className="belive-content-box">
                    <h3 className="belive-heading">10 लाख +</h3>
                    <p className="belive-sub-heading">
                      खरीदार जुड़े हैं मूफार्म के साथ।
                    </p>
                  </div>
                </div>
                <div className="col-xs-6 belive-box right">
                  <img
                    src={BeliveImg2}
                    alt="Belive Img"
                    className="belive-img"
                  />
                  <div className="belive-content-box">
                    <h3 className="belive-heading">1 लाख +</h3>
                    <p className="belive-sub-heading">
                      पशु बिक चुके हैं मूफार्म के द्वारा।
                    </p>
                  </div>
                </div>
              </div>
              <div className="row belive-four-point-row2">
                <div className="col-xs-6 belive-box left">
                  <img
                    src={BeliveImg3}
                    alt="Belive Img"
                    className="belive-img"
                  />
                  <div className="belive-content-box">
                    <h3 className="belive-heading">200 +</h3>
                    <p className="belive-sub-heading">
                      शहरों में हैं मूफार्म की पहुंच।
                    </p>
                  </div>
                </div>
                <div className="col-xs-6 belive-box right">
                  <img
                    src={BeliveImg4}
                    alt="Belive Img"
                    className="belive-img"
                  />
                  <div className="belive-content-box">
                    <h3 className="belive-heading">10 +</h3>
                    <p className="belive-sub-heading">
                      नस्लें उपलब्ध हैं मूफार्म पर।
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 last-video-slider">
              <h3 className="easy-heading">खरीदारों की राय जाने</h3>
              <Slider {...settings2}>
                {testimonialVideos.map((el, index) => {
                  return (
                    <div>
                      <div className="sell-Slide-video-Box">
                        <div className="sell-video-player">
                          <VideoPlayer
                            key={index}
                            refs={ref => (this[`myRefs${index}`] = ref)}
                            url={el.url}
                          />
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          </div>
        </div>

        <div className="container sell-popup-button-container">
          <div className="row sell-popup-button-row text-center">
            <div className="col-xs-12">
              <div className="sell-popup-button">
                <a
                  className="popupbtn"
                  data-toggle="modal"
                  data-target="#myModal"
                  onClick={() => {
                    this.setState(
                      { showThanksMessage: false, mobile: "" },
                      () => {
                        return(
                        this.fbqTrack("LP Buy Initiated ",{utmObj}),
                        window.analytics.track("LP Buy Initiated",{utmObj})
                        )
                      }
                    )
                  }}
                >
                  फार्म पर आएं - पशु देखें
                  <img
                    src={ArrowBtn}
                    className="btn-arrow-bottom"
                    alt="Arrow Btn"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container sell-footer-container">
          <div className="row text-left">
            <div className="col-xs-6 feed-social-background">
              <img src={LandingLogo} alt="Logo" className="footer-logo" />
              <div className="sell-social-icon">
                <a href="https://www.instagram.com/mooofarm/" target="_blank">
                  <img
                    src={sellInstagram}
                    alt="facebook"
                    className="sell-social"
                  />
                </a>
                <a href="https://www.facebook.com/mooofarm" target="_blank">
                  <img
                    src={sellFacebook}
                    alt="Instagram"
                    className="sell-social"
                  />
                </a>
                <a
                  href="https://in.linkedin.com/company/mooofarm"
                  target="_blank"
                >
                  <img
                    src={sellLinkedin}
                    alt="Youtube"
                    className="sell-social"
                  />
                </a>
                <a
                  href="https://www.youtube.com/c/MooofarmYoutube"
                  target="_blank"
                >
                  <img
                    src={sellYoutube}
                    alt="Linkedin"
                    className="sell-social"
                  />
                </a>
              </div>
            </div>
            <div className="col-xs-6 google-play-button">
              <p className="google-paly-heading">
                डाउनलोड करें गूगल प्ले स्टोर से
              </p>
              <img src={Rating} alt="Rating" className="rating" />
              <a
                href="https://play.google.com/store/apps/details?id=com.moo&hl=en_IN"
                target="_blank"
              >
                <img
                  src={GooglePlay}
                  alt="Google Play"
                  className="google-img"
                />
              </a>
            </div>
          </div>
        </div>

        <div id="myModal" class="modal fade" role="dialog">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  onClick={() => {
                    this.setState({ showMessage: false })
                  }}
                >
                  &times;
                </button>
                {!this.state.showThanksMessage && (
                  <p class="modal-title">
                    यहाँ अपना नंबर भरें - हमारी टीम आपसे तुरंत संपर्क करेगी!
                  </p>
                )}
              </div>
              {!this.state.showThanksMessage ? (
                <>
                  <div class="modal-body">
                    <div className="row">
                      <div className="col-xs-9">
                        <input
                          type="tel"
                          class="tel-input"
                          placeholder="अपना नंबर यहां भरें"
                          name="tel"
                          min="1"
                          max="10"
                          value={this.state.mobile}
                          required
                          onChange={e => {
                            this.handleMobileInputChange(e)
                          }}
                        />
                        <span className="country-code">+91</span>
                      </div>
                      <div className="col-xs-3">
                        <a
                          // href={this.state.mobilehref}
                          className="phone-btn"
                          onClick={this.handleMobileChange}
                          // data-toggle="modal"
                          // data-target="#myModal"
                        >
                          पशु ख़रीदें
                        </a>
                      </div>
                    </div>
                    <div>
                      {this.state.showMessage && (
                        <p className="warning">
                          कृपया 10 अंको का मोबाइल नंबर डाले
                        </p>
                      )}
                    </div>
                    <div style={{ paddingTop: 4 }}>
                      <p className="save-number">
                        आपका नंबर हमारे पास सुरक्षित है
                      </p>
                    </div>
                  </div>
                  <div class="modal-footer"></div>
                </>
              ) : (
                <p className="modal-title success-meesage">
                  धन्यवाद, हमारी टीम आपसे तुरंत संपर्क करेगी!
                </p>
              )}
            </div>
          </div>
        </div>

        <div id="videoModal" class="modal fade" role="dialog">
          <div class="modal-dialog">
            <div className="modal-video">
              <iframe
                width="100%"
                height="300"
                src="https://www.youtube.com/embed/GWWg2_lPLQg"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Landing
